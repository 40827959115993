/*
    w2wCookies
    Better cookiebar.

    Options:
    - targetAccept     string  '.js-cookiebar-accept'
    - expireDays        int     365
    - path              string  '/'
    - hideCookiebarTime bool    1000

    Dependencies:
    - jQuery
    - jQuery Cookie
*/
(function(w2wCookies) {
    'use strict';

    /* configuration */
    w2wCookies.debugging    = false;
    w2wCookies.accepted     = false;
    w2wCookies.cookiebar    = false;

    w2wCookies.hideCookiebar = function(w2woptions) {

        if (w2wCookies.cookiebar === false) {
            return;
        }

        w2wCookies.cookiebar.slideUp(w2woptions.hideCookiebarTime);
    };

    w2wCookies.setCookie = function(event, options) {
        w2wCookies.accepted = true;

        $.cookie('w2wCookie', 'accepted', {
            expires: options.expireDays,
            path: options.path
        });
    };

    w2wCookies.cookie = function() {
        return w2wCookies.accepted;
    };

    w2wCookies.init = function(target, options){
        //merge custom options, with the w2wCookies options
        var $cookiebar      = false;
        var $targetAccept  = false;

        if (options === undefined) {
            options = {};
        }

        var w2wOptions = {
            'targetAccept': '.js-cookiebar-accept',
            'expireDays': 365,
            'path': '/',
            'hideCookiebarTime': 1000
        };

        var mergedOptions = $.extend(w2wOptions, options);

        $('body').on("w2w-cookieAccepted",  w2wCookies.setCookie);

        $cookiebar = $(target);

        if($.cookie('w2wCookie') !== undefined) {

            $('body').trigger("w2w-cookieAccepted", [w2wOptions]);
            return true;

        }

        w2wCookies.cookiebar = $cookiebar;

        // If cookie not set, cookiebar is always visible.
        $targetAccept = $cookiebar.find(w2wOptions.targetAccept);
        if( $targetAccept.length < 1 ) {
            console.log('[error] Accept target not found, set an element for accepting cookies.');
            return;
        }

        $('body').on("w2w-cookieAccepted", w2wCookies.hideCookiebar);

        $targetAccept.on('click', function (e) {
            e.preventDefault();
            $('body').trigger("w2w-cookieAccepted", [w2wOptions]);
        });
    };

})(window.w2wCookies = (window.w2wCookies || {}));
