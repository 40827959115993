(function(Menu) {
    'use strict';

    Menu.initToggle = function() {
        var $toggle  = $('.js-menu-toggle');
        var $menu    = $('.js-menu-overlay');
        var $icon    = true;

        $toggle.on('click', function(event) {
            event.preventDefault();

            $menu.toggleClass('is-hidden');

            if ($icon) {
                $toggle.toggleClass('is-active');
            }
        });
    };

})(window.Way2web.Menu = window.Way2web.Menu || {});
