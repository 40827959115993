(function(Way2web) {
    'use strict';

    Way2web.onDomReady = function() {
        w2wCookies.init('.js-cookiebar');
        Way2web.Menu.initToggle();
        Way2web.FancyScroll.init();
    };

})(window.Way2web = window.Way2web || {});

$(document).ready(Way2web.onDomReady);
