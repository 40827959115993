(function(FancyScroll) {
    'use strict';
    FancyScroll.scrollSpeed = 1000;

    FancyScroll.init = function() {
        FancyScroll.onPageLoad();
        FancyScroll.bindClick();
    };

    FancyScroll.bindClick = function() {
        var selector = 'a[href*="#"]:not([href="#"])';
        $(selector).click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    FancyScroll.doScroll(target);
                }
            }
        });
    };

    FancyScroll.onPageLoad = function() {
        var currentHash = window.location.hash;
        if (currentHash !== '') {
            var target = $(currentHash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                FancyScroll.doScroll(target);
            }
        }
    };

    FancyScroll.doScroll = function(target) {
        $('html,body').animate({
            scrollTop: target.offset().top
        }, FancyScroll.scrollSpeed);

        return false;
    };

})(window.Way2web.FancyScroll = window.Way2web.FancyScroll || {});
